<template>
  <div class="register">
    <Heading noDecoration type="h3" text-align="center">
      Invite attendees
    </Heading>
    <SectionWithBg
      growing-body
      min-width-heading
      heading-top
      heading-position="left"
    >
      <template #heading> {{ ticketType }} </template>
      <template #body>
        <div v-if="inviteSuccess" class="register__success">
          Thank you! <br />
          We've sent invitations to the specified emails <br />
          <Link :link="`/${$route.params.id}`">Home page</Link>
          <br />
          <Link
            v-if="isTicketSponsors"
            :link="`/${$route.params.id}/exhibitor-kit/${userCodesToken}`"
          >
            Exhibitor kit with my codes
          </Link>
          <Link
            v-else-if="isTicketSpeakers"
            :link="`/${$route.params.id}/speaker-kit/${userCodesToken}`"
          >
            Speaker kit with my codes
          </Link>
          <Link v-else :link="`/${$route.params.id}/tickets/${userCodesToken}`"
            >My codes</Link
          >
        </div>
        <div v-else class="register__wrapper">
          <Link
            v-if="isTicketSponsors"
            :link="`/${$route.params.id}/exhibitor-kit/${userCodesToken}`"
          >
            Click to return to exhibitor kit page with codes
          </Link>
          <Link
            v-else-if="isTicketSpeakers"
            :link="`/${$route.params.id}/speaker-kit/${userCodesToken}`"
          >
            Click to return to speaker kit page with codes
          </Link>
          <Link v-else :link="`/${$route.params.id}/tickets/${userCodesToken}`">
            Click to return to codes page
          </Link>
          <form class="mt-6">
            <div
              class="nested__item d-flex"
              v-for="(item, counter) in arrayItems"
              v-bind:key="item + counter"
            >
              <Input
                v-model="item.value"
                :error="item.error"
                :name="`email-${counter}`"
                label="Email"
                type="text"
                @input="clearError(counter)"
                @blur="validate(counter)"
              />
              <img
                @click="deleteNested(counter)"
                :src="require('@/assets/img/icons/close.svg')"
                alt="delete"
                class="ml-3"
                style="cursor: pointer"
              />
            </div>
            <div v-if="formValidationError" class="register__error">
              {{ formValidationError }}
            </div>
            <transition name="fade">
              <Error v-if="inviteError">
                <template #heading>{{
                  inviteError || "Sorry, something went wrong"
                }}</template>
                <template #body>
                  Please try again or contact us at
                  <a href="mailto:info@arctic-con.com" class="error__link">
                    info@arctic-con.com
                  </a>
                </template>
              </Error>
            </transition>
            <Preloader :loading="loading" />
            <Button
              v-show="!loading"
              fullWidth
              white
              class="mt-3"
              @click="addNested"
              :disabled="arrayItems.length >= 10"
            >
              Add new email
            </Button>
            <Button
              v-show="!loading"
              fullWidth
              class="mt-5"
              @click.prevent="submitInvitation"
            >
              send invitation
            </Button>
          </form>
        </div>
      </template>
    </SectionWithBg>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import SectionWithBg from "@/components/common/SectionWithBg";
import Preloader from "@/components/common/Preloader";
import Input from "@/components/common/Input";
import Link from "@/components/common/Link";
import Button from "@/components/common/Button";
import Error from "@/components/common/Error";
import { mapActions, mapState } from "vuex";

export default {
  name: "InviteAttendee",
  props: {},
  components: {
    Heading,
    Preloader,
    SectionWithBg,
    Input,
    Link,
    Button,
    Error,
  },
  data: () => ({
    loading: false,
    ticketType: "",
    isTicketSponsors: false,
    isTicketSpeakers: false,
    ticketFeatures: [],
    formValidationError: null,
    inviteError: null,
    inviteSuccess: false,
    userCodesToken: null,
    arrayItems: [{ value: "", error: "", rules: ["required", "email"] }],
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    let ticket;
    if (JSON.parse(localStorage.getItem("chosen-sponsor-code"))) {
      ticket = JSON.parse(localStorage.getItem("chosen-sponsor-code"));
      this.isTicketSponsors = true;
    } else if (JSON.parse(localStorage.getItem("chosen-speaker-code"))) {
      ticket = JSON.parse(localStorage.getItem("chosen-speaker-code"));
      this.isTicketSpeakers = true;
    } else {
      ticket = JSON.parse(localStorage.getItem("chosen-code"));
    }
    this.ticketType = ticket.title;
    this.ticketFeatures = ticket.description;
    this.userCodesToken = JSON.parse(localStorage.getItem("codes-token"));
  },
  methods: {
    ...mapActions(["PUT"]),
    validate(idx) {
      const rule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!rule.test(this.arrayItems[idx].value)) {
        this.arrayItems[idx].error = this.arrayItems[idx].rules.includes(
          "required"
        )
          ? "You should enter at least 1 valid email"
          : "Please enter valid Email";
      }
    },
    clearError(idx) {
      this.formValidationError = null;
      this.arrayItems[idx].error = "";
    },
    addNested(e) {
      e.preventDefault();
      if (this.arrayItems.length < 10)
        this.arrayItems.push({ value: "", error: "", rules: ["email"] });
    },
    deleteNested(counter) {
      if (this.arrayItems.length > 1) this.arrayItems.splice(counter, 1);
    },
    submitInvitation() {
      this.inviteError = null;
      this.formValidationError = null;

      const emailsList = Array.from(
        this.arrayItems,
        (item) => item.value
      ).filter(Boolean);

      if (!emailsList.length) {
        this.formValidationError =
          "You should enter at least 1 email to send the invitation";
        return;
      }

      this.loading = true;

      const query = {
        code: this.$route.params.code,
        emails: emailsList,
      };

      this.PUT({
        route: `/ticketing/public/event/${this.$route.params.id}/code/${this.$route.params.code}/invite`,
        data: query,
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.inviteSuccess = true;
        })
        .catch((err) => {
          this.inviteError = err.response.data.msg;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.register {
  padding-top: 106px;

  &__wrapper {
    width: 80%;
  }

  &__error {
    text-align: center;
    color: $error-red;
  }

  &__success {
    width: 80%;
    text-align: center;
    font-size: 1.3rem;
  }
}
</style>
