<template>
  <InviteAttendee />
</template>

<script>
import InviteAttendee from "@/components/ticketsRegistration/InviteAttendee";

export default {
  name: "InviteAttendeePage",
  props: {},
  components: {
    InviteAttendee,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
